<template>
  <div class="main">
    <a-row>
      <a-col :span="8">
        <a-page-header title="百家姓" sub-title="百家姓列表，共195个" />
      </a-col>
    </a-row>
    <!-- 菜单列表 -->
    <a-spin tip="Loading..." :spinning="listLoading">
      <div class="contetn">
        <a-avatar class="item" v-for="i in data" :key="i.id" shape="square" :size="50" :style="{ backgroundColor: '#747474', verticalAlign: 'middle' }">{{i.xing}}</a-avatar>
      </div>
    </a-spin>
    <!-- 菜单列表 End -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableTitle: [
        { title: "订单号", dataIndex: "order_id", key: "order_id" },
        { title: "类型", dataIndex: "type", key: "type", scopedSlots: { customRender: "type" }},
        { title: "手机号", dataIndex: "phone", key: "phone" },
        { title: "支付时间", dataIndex: "time", key: "time", width: '160px'},
        { title: "金额(元)", dataIndex: "money", key: "money", scopedSlots: { customRender: "money" }},
        { title: "支付", dataIndex: "pay", key: "pay", scopedSlots: { customRender: "pay" }},
        { title: "操作", key: "action", scopedSlots: { customRender: "action" },width:"100px"}
      ],
      data: [],
      listLoading: false, //列表loadding
    };
  },
  mounted() {
    this.getListData();
  },
  methods: {
    getListData() {
      this.listLoading = true;
      this.$http
        .get("/admin/name/xingList")
        .then(res => {
          //请求成功后的处理函数
          this.listLoading = false;
          this.data = res.data.result
        })
        .catch(() => {
          //请求失败后的处理函数
          this.listLoading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
  .contetn{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > .item{
      margin-right: 16px;
      margin-bottom: 16px;
    }
  }
</style>